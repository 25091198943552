@import '../../variables.scss';

#swipper { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}
body {
  background: #eee;
  font-family: 'Poppins', sans-serif!important;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
#swipper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  font-size: 18px;
  font-weight: normal;
  color: #000;
  box-shadow: 3px 5px 20px rgb(0 0 0 / 9%);
  background-color: whitesmoke;
}
.green-circle {
  background-image: linear-gradient(5deg, $icongreen,#ffffff);
  border-radius: 50%;
  height: 143px;
  right: 0;
  position: absolute;
  top: 20%;
  width: 140px;
  z-index: -98;
}
.green-circle-small {
  background-image: linear-gradient(3deg, $icongreen,#ffffff);
  border-radius: 50%;
  height: 50px;
  right: 50%;
  position: absolute;
  top: 50%;
  width: 45px;
  z-index: -90;
}
.bus {
  background-image: url('../../assets/bus.png');
  height: auto;
  width: 260px;
  left: 10%;
  position: absolute;
  top: 60%;
  z-index: 10;
  @media screen and (max-width: 768px) {
    display: none;
  }
}