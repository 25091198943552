@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../variables.scss';

#homeHero {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.685), rgba(249, 249, 249, 0)), url('../../assets/connor.jpg');
    min-height: 100vh;
    background-size: cover;
    background-repeat: 'no-repeat';
    .heroImage  {
      max-width: 80%;
      height: auto;
    }
    h2 {
      font-size: 64px;
      font-weight: 800;
      color: whitesmoke;
      letter-spacing: 1px;
      word-spacing: 2px;
      opacity: 0.8;
    } 
    p {
      font-size: 18px;
      font-weight: 600;
      opacity: 0.8;
    }
    label {
        color: white;
        margin-bottom: 0.75rem;
    }
    input, button, select {
        border-radius: 5px;
        padding: 0.5rem 0.8rem;
        margin-bottom: 0.75rem;
        background-color: rgba(66, 66, 66, 0.371);
        color: white;
        border: 1px solid black;
        &:hover, &:focus {
            border: 1px solid white;
        }
        &::placeholder {
          color: rgba(255, 255, 255, 0.501);
        }         
    }
    button {
      padding: 0.75rem 0.8rem;
      border-radius: 0;
      border: 0;
      &:hover, &:focus {
        background-color: rgba(66, 66, 66, 0.647); 
        color: rgb(208, 205, 205);
        border: 1px;
    }
    }
    form {
      padding: 1rem;
      background-image: linear-gradient(to top, rgba(18, 18, 18, 0.334), rgba(0, 0, 0, 0));
      backdrop-filter: blur(20px);
    }
  }
  @media screen and (max-width: 768px) {
    #homeHero {
      height: auto;
      .heroImage  {
        max-width: 50%;
        height: auto;
      }h2 {
        font-size: 48px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 996px) and (min-width: 740px) {
    #homeHero {
      .heroImage {
        max-width: 100%;
      }h2 {
        font-size: 50px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    #homeHero {
      h2 {
        font-size: 32px;
      }
    }
  }
#homeservices {
  img{
    width: 40px;
    height: 40px;
  }
  .cardex {    
    border: 2px solid rgba(33,63,125,.06);
    border-radius: 5px;
    box-shadow: 3px 5px 20px rgb(0 0 0 / 4%);
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    &:hover{
      border: 2px solid $darkgcol;
    }
  } 
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
  hr {
    background: $icongreen;
    border: 2px solid transparent;
    margin: 0;
  }
}
#TopRoutes {
  #image-slider {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: scroll;
    box-sizing: border-box;
    .sliderT {
      margin: 0 20px 0 0;      
      img {
        width: 320px;
      }
    }
    img {
      width: 300px;
      height: 200px;
    }
  }
  .fa-star {
    margin-right: 0.5rem;
    //color: rgb(160, 160, 16);
    color: rgb(205, 192, 14);
  }
  .fa-location-dot {
    color: rgb(179, 179, 179);
  }
  button {
    background-color: $icongreen;
}
#image-slider::-webkit-scrollbar {
    width: 0;
}
@media screen and (max-width: 768px) {
    #image-slider {
        .sliderT img {
            width: 250px;
        }
    }
  }
}
#CHOOSEUS {
  background-image: linear-gradient(to top, rgb(239, 243, 239), rgba(233, 255, 255, 0.074));
}
#chooseus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  word-wrap: break-word;
}
@media (min-width: 567px) and (max-width: 768px) {
  #chooseus {
      width: 100%;
  }
}
@media screen and (max-width: 567px) {
  #chooseus {
      flex-direction: column;
  }
}