@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import '../../variables.scss';

.footer {
    background-color: rgba(11, 11, 11, 0.966); 
    width: 100%;
    object-fit: contain;
    display:flex;
    color: rgba(245, 245, 245, 0.897);
    @media screen and (max-width:768px) {
        //background-image: url('../../assets/green-wave-haikei.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    }
    li {
        list-style: none; 
        padding-bottom: 0.5rem;       
    }
    a{
        text-decoration: unset;
        color: inherit;
        &:hover{
            color: rgba(255, 255, 255, 0.484);
            cursor: pointer;
        }
    }
    hr {
        border: 1px solid $logoColor;
        background-color: $logoColor;
    }
    .mailInput{
        background-color: unset!important;
        color: white;
        border: 0;
        border-bottom: 2px solid white;
        &::placeholder{
            color: rgb(117, 116, 116);
        }
        @media screen and (max-width: 768px) {
            &::placeholder{
                color: rgb(255, 255, 255);
            }            
        }
        &:focus-visible{
            border: 0;
            border-bottom: 2px solid white;
            outline: 0;
        }
    }
}