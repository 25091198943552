@import '../../variables.scss';

@-moz-keyframes throbber-loader {
    0% {
      background: #dde2e7;
    }
    10% {
      background: $logoColor;
    }
    40% {
      background: #dde2e7;
    }
  }
  @-webkit-keyframes throbber-loader {
    0% {
      background: #dde2e7;
    }
    10% {
      background: $logoColor;
    }
    40% {
      background: #dde2e7;
    }
  }
  @keyframes throbber-loader {
    0% {
      background: #dde2e7;
    }
    10% {
      background: $logoColor;
    }
    40% {
      background: #dde2e7;
    }
  }
  /* :not(:required) hides these rules from IE9 and below */
  .throbber-loader:not(:required) {
    -moz-animation: throbber-loader 2000ms 300ms infinite ease-out;
    -webkit-animation: throbber-loader 2000ms 300ms infinite ease-out;
    animation: throbber-loader 2000ms 300ms infinite ease-out;
    background: #dde2e7;
    display: inline-block;
    position: relative;
    text-indent: -9999px;
    width: 0.75em;
    height: 1.75em;
    margin: 0 1.6em;
  }
  .throbber-loader:not(:required):before, .throbber-loader:not(:required):after {
    background: #dde2e7;
    content: '\x200B';
    display: inline-block;
    width: 0.75em;
    height: 1.75em;
    position: absolute;
    top: 0;
  }
  .throbber-loader:not(:required):before {
    -moz-animation: throbber-loader 2000ms 150ms infinite ease-out;
    -webkit-animation: throbber-loader 2000ms 150ms infinite ease-out;
    animation: throbber-loader 2000ms 150ms infinite ease-out;
    left: -1.6em;
  }
  .throbber-loader:not(:required):after {
    -moz-animation: throbber-loader 2000ms 450ms infinite ease-out;
    -webkit-animation: throbber-loader 2000ms 450ms infinite ease-out;
    animation: throbber-loader 2000ms 450ms infinite ease-out;
    right: -1.6em;
  }
  