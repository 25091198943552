@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './variables.scss';
//@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Roboto:wght@100&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.paystack-button {
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    background-color: $logoColor;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 100%;
    height: 45px;
  }
  
  .paystack-button:hover {
    background-color: #94ee0e6f;
    ;
  }
  
  .paystack-button:focus {
    outline: none;
  }


body {
    font-family: 'Poppins', sans-serif!important;
}

$navHeight: 80px;

.vh-75 {
    height: 75vh;
}


// FAQs
#FAQs {
    .faq-hero{
        background: url('./assets/ask1.jpg') center center/contain no-repeat;
        width: 100%;
        height: 75vh;
        margin-top: 3rem;
    }
    h2, h5 {
        font-weight: 500;
    }
    p {
        font-size: 16px;
        color: #5f6368;
    }
    .accordion-body{
        font-size: 16px;
        color: #5f6368df;
    }
    .accordion-button{
        font-weight: 600;
    }
    .disclaimer{
        color: #bdbdbd;
    }
   .accordion {
    .accordion-button {
        border-left: none;
        border-right: none;
        border-top: none;        
        border-bottom: 2px solid rgba(0, 0, 0, 0.125);
        border-bottom-right-radius: 0.25rem;
        padding-left: 0.25rem;
        padding-right: 1.25rem;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
    .accordion-collapse {
        border: none;
        border-width: 0 1px;
      }
    .accordion-button:not(.collapsed) {
    color: black;
    background-color: rgb(229, 243, 229);
    }
    .accordion-button:focus {
        z-index: 1;
        border-bottom: rgba(229, 243, 229, 0.578);
        outline: 0;
        box-shadow: none;
    }
    .accordion-button::after, .accordion-button:not(.collapsed)::after {
        color: black!important;
    }
    .accordion-item{
        border: none;
    }
   }
}

#Error {    
    img {
        width: 300px;
        height: auto;
    }
    h2 {        
        color: #5f6368;
        font-size: 4rem;
    }
    a {
        text-decoration: none;
        color: white;        
        background-color: #5f6368;
    }
    h5 {
        font-size: 18px;
        color: black;
    }
    button {
        background-color: $eucalyptus;
        background-color: $eucalyptus;
        border-radius: 10px;
        border: 0;
        color: rgba(245, 245, 245, 0.836);
    }
} 
#about {
}
#whatwedo{
    background-color: $eucalyptus;
}

// swiper
.swiper-button-next, .swiper-button-prev {
    color: #fff;
    background: -webkit-linear-gradient(#96ee0e, #44D7A8);
    border-radius: 50px;
    width: 60px!important;
    height: 60px!important;
    padding: 1rem 2rem;
    @media screen and (max-width: 768px) {
        width: 30px;        
        height: 20px;
        padding: 0.5rem 1rem;
    }
}

.swiper-pagination-bullet-active {
    background: #2ECC71;
}

.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 16px;
    @media screen and (max-width: 768px) {
        font-size: 14px;        
    }
}