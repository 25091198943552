@import '../variables.scss';

#Registration{
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(../assets/background.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  margin-top: 5rem;
  padding-top: 2rem;
  padding-bottom: 3rem;
.booking-form-box {
  max-width: 350px;
  border: 2px solid #ced4da;
  margin: 10% auto 0;
}

.radio-btn{
  margin: 0 20px;
}

.radio-btn btn{
  margin: 20px 10px 20px 0;
  box-shadow: none;
}

.radio-btn .btn::after{
  content: '';
  height: 15px;
  width: 15px;
  top: -2px;
  left: -1px;
  background-color: #fff;
  position: relative;
  display: inline-block;
  border: 3px solid #fff;
  visibility: visible;
  border-radius: 50%;
}

.radio-btn .btn:checked::after{
  content: '';
  background-color: #000;
  transition: 0.5s;
}

.radio-btn span{
  color: black;
}

.booking-form{
  padding: 0 20px 20px;
}
.booking-form label{
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
}

input::placeholder{
  color: #fff !important;
}

.input-grp{
  width: 150px;
  display: inline-block;
}

.form-control{
  font-size: 14px!important; 
  border-radius: 0!important;
  color: #fff!important;
  background: transparent!important;
  box-shadow: none!important;
}

.select-date::-webkit-inner-spin-button,
.select-date::-webkit-outer-spin-button{
  -webkit-appearance: none;
   margin: 0;
}

.select-date::-webkit-calendar-picker-indicator{
  background-color: transparent!important;
}

.custom-select{
  color: #fff!important;
  font-size: 14px!important;
  border: 1px solid #ced4da!important;
  box-shadow: none!important;
  border-radius: 0!important;
  background: transparent!important;
  padding: 9px 0;
  width: 150px;
}

.custom-select option {
  color: #333;
}

.book {
  width: 100%;
  padding: 9px 0!important;
  font-size: 14px!important;
  border: 1px solid #ced4da!important;
  box-shadow: none!important;
  background: transparent!important;
  border-radius: 0!important;
}

.book:hover {
  background: #fff!important;
  color: #000!important;
  transition: 0.5s;
}
}