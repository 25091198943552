#contact {
    //margin-top: 6rem;
    form {
        border: 1px solid rgba(84,95,125,.15);
        border-radius: 8px;
    }
    input {
        width: 100%;
        border-radius: 8px;
        padding-left: 0.5rem;
        outline: 0;
        border: 2px solid rgba(84,95,125,.15);
        &:focus{
            border: 1px solid green;
        }
    }
    textarea {
        width: 100%;
        border-radius: 8px;
        padding-left: 0.5rem;
        outline: 0;
        border: 2px solid rgba(84,95,125,.15);
    }
}
#contact-hero {
    background-image: url('../../assets/wave-haikei.png');
    height: 50vh;
    background-position: top;
    background-size: center;
    background-repeat:repeat-x;
    .contact-hero {
        padding-top: 8rem;
        h2 {
            color: whitesmoke;
            font-weight: 800;
            font-family: 'Tiro Devanagari Sanskrit', serif;
        }
        p {
            color: rgba(255, 255, 255, 0.736);
            font-weight: 600;
            margin: 0;
            @media screen and (max-width: 768px) {
                width: 75%;
                margin: auto;
            }
        }
    }
    .firstpicture {
        position: absolute;
        width: 80px;
        height: auto;
        top: 120%;
        left: 28%;
        @media screen and (max-width:768px) {
            left: 15%;
            width: 60px;
            top: 110%;
        }
        @media screen and (max-width:480px) {
            left: 15%;
            width: 60px;
            top: 98%;
        }
    }
    .secondpicture {
        position: absolute;
        width: 100px;
        height: auto;
        top: 110%;
        right: 28%;
        @media screen and (max-width:768px) {
            right: 18%;
            width: 75px;
            top: 120%;
        }
        @media screen and (max-width:480px) {
            right: 18%;
            width: 55px;
            top: 110%;
        }
    }
}