//@import url('https://fonts.googleapis.com/css2?family=Lato&family=Tiro+Devanagari+Sanskrit&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Rubik&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

$col-30: 30%;
$lightglcol: rgb(229, 243, 229);
$darkgcol: #074506e6;
$eucalyptus: #44D7A8;
$greenScreen: #00B140;
$col-47-5: 47.5%;
$logoColor: #96ee0e;
$icongreen: #2ECC71;

.col-47-5 {
  width: $col-47-5;
}
.logoColor {
  color: $logoColor;
}
.icongreen {
  color: $icongreen;
}
.greenScreen {
  color: $greenScreen;
}
.eucalyptus {
  color: $eucalyptus;
}
.greenColor{
  color: $lightglcol;
}
.darkgcol{
  color: $darkgcol;
}
.vh-50{
  height: 50vh;
}
.vh-30{
  height: 30vh;
}

@media screen and (min-width: 768px) {
    .col-30 {
        width: $col-30;
    }
}