@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../variables.scss';

.button {
  border-radius: 5px;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.navbarT {
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  padding-bottom: 3rem;
  z-index: 999;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  background-color: rgb(48, 52, 43);
}
.navbarT.active {
  background-color: rgba(18, 18, 18, 0.2);
  background-color: none;
  backdrop-filter: blur(20px);
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: flex-start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.nav-menu {
  display: flex;
  grid-gap: 15px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: flex-end;
  margin-right: 2rem;
  margin: auto;
}

.nav-item {
  height: 80px;
  a {
    padding: 0 1rem;
  }
}
.nav-links {
  //color: rgb(16, 20, 17);
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0;
  height: 100%;
}
.nav-links:hover {
  //color: rgb(196, 194, 194);
  //color: $eucalyptus;
  color: #a8fe26fb;
  background-color: rgb(158, 158, 158);
  text-decoration-color: rgba(215, 215, 215, 0.642);
  transition: all 0.2s ease-out;
  text-shadow: black;
}
.fa-bars,
.fa-xmark {
  color: black;
  z-index: 4;
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}
@media screen and (max-width: 768px) {
  .NavbarItems {
    position: relative;
  }
  ol,
  ul {
    padding-left: 0;
  }
  .fa-xmark {
    color: white;
  }
  .nav-item {
    align-items: center;
    a {
      padding: 2rem;
    }
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    z-index: 2;
    left: -100%;
    transition: all 0.5s ease;
    justify-content: center;
  }
  .nav-menu.active {
    background-color: rgba(18, 18, 18, 0.99)!important;
    backdrop-filter: blur(10px);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 2;
  }
  .nav-links {
    color: rgba(255, 255, 255, 0.911);
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    background-color: rgba(255, 255, 255, 0.31); 
  }
  // .navbar-logo {
  //   position: absolute;
  //   left: 0;
  //   margin-top: 40px;
  //   margin-left: 0;
  //   top: -95%;
  // }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 3;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
ul {
  padding-left: 0 !important;
}
// Button {
//   .book-btn {
//     border: 2px solid green;
//     border-radius: 20px;
//     background-color: inherit;
//     color: white;
//     &:hover{
//         border-color: greenyellow;
//     }
//     &:active{
//         border-color: none;
//     }
//     &:focus{
//         animation-name: bouncy;
//         animation-duration: 0.25s;

//     }
// @keyframes bouncy {
// 0%   {border: 2px solid rgb(4, 103, 4);}
// 25%  {border: 3px solid #ffe6e6;}
// 50%  {border: 3px solid rgb(3, 164, 3)}
// 100% {border: 2px solid rgb(4, 103, 4);}
// }

// }
// }
