@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

#About {
  #AboutHero {
    background-image: linear-gradient(
        to top,
        rgba(24, 24, 24, 0.533),
        rgba(249, 249, 249, 0)
      ),
      url('../../assets/abouthero2.jpg');
    height: 50vh;
    background-size: cover;
    background-repeat: 'no-repeat';
    background-position: center;
    color: white;
    h1 {
      font-size: 64px;
    }
    .line {
      height: 2px;
      width: 35px;
      background-color: white;
    }
  }
  #whoweare {
    .line2 {
      height: 3px;
      width: 4rem;
      background-color: gray;
    }
  }
  header {
    font-size: 32px;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }
}
